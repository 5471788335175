// document.addEventListener("turbo:load", function() {
//
//   alert('coucou');
//
// });

const groupTypeMod = {

    init: function () {


        // Select all checkboxes with the name 'settings' using querySelectorAll.
        const checkboxes = document.querySelectorAll(".group_type_toggle");
        let enabledSettings = []

        // Use Array.forEach to add an event listener to each checkbox.
        checkboxes.forEach(function(checkbox) {
            checkbox.addEventListener('change', function(event ) {

                const element = event.target
                const feature_id = element.dataset.feature
                const group_type_id = element.dataset.id

                $.ajax({
                    method: 'PATCH',
                    url: `/features/${feature_id}/toggle_group`,

                    data: {
                        group_type_id: group_type_id
                    },

                    success: (data) => {
                    },

                    error: () => {
                        // TODO - remonter le message
                        alert('Erreur inconnu 005, veuillez contacter le support.')
                    },

                    dataType: 'script'
                });

            })
        });

    }
}

export default groupTypeMod
